<template>
	<div class="clauseBox">
		<p>귀위안 주식회사는 개인정보보호법에 따라 이용자의 소중한 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 취급방침을 두고 있습니다.
		</p><p>이 개인정보취급방침의 내용은 다음과 같습니다.
		</p><p>제 1 조 (개인 정보의 수집)
		</p><p>（1）이름, 사용자 이름 또는 별명
		</p><p>（2）이메일 주소
		</p><p>（3）페이스북 연동 게임: 페이스북 ID, 휴대폰번호(선택 항목), 친구목록 및 친구의 프로필사진
		</p><p>（4）구글 연동 게임: 필수항목(구글ID), 휴대폰번호(선택 항목)
		</p><p>（5）이용자의 휴대폰 단말기 정보(모델명, OS버전, 모바일 펌웨어 버전, 기기고유번호 등), IP Address, 쿠키, 마지막 접속 위치
		</p><p>（6）고객지원 서비스: 휴대폰번호, 이름, SMS 수신 동의 여부
		</p><p>（7）이벤트 및 프로모션: 이름, 주소, 휴대폰번호, 전화번호, 우편번호, 이메일
		</p><p>（8）사용자 프로필 (프로필 사진, 사용자 이름 또는 별명, 성별, 경력, SNS에서의 개인 정보에 대한 링크 기타 사용자가 프로필로 제공하는 정보, 사용자 ID)
		</p><p>제 2 조 (개인 정보를 수집 · 이용 목적)
		</p><p>개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.
		</p><p>（1）이용자로부터의 불만처리와 문의를 답변 및 연락
		</p><p>（2）앱내에 이용 상황에 대한 분석
		</p><p>（3）광고 및 설문 등의 전달 및 분석
		</p><p>（4）대금 청구 등의 결제환불
		</p><p>（5）본 앱 등의 서비스 제공 차원
		</p><p>（6）콘텐츠, 게임 내 통화, 포인트 등의 부여 등을 하기 위해
		</p><p>（7）이용자에게 고지 사항 등을 보내기 위해
		</p><p>（8）기타 컨텐츠 제공 및 인증 서비스 (ID/PW찾기 등)
		</p><p>제 3 조 (개인 정보의 수집 방법)
		</p><p>회사는 다음과 같은 방법으로 개임정보를 수집하니다.
		</p><p>（1）회사의 서비스 가입 시 동의 절차제공을 통해 수집
		</p><p>（2）프로모션 및 이벤트 진행을 위해서는 별도의 동의절차를 통해 수집
		</p><p>（3）회사와 서비스 제공관련 제휴관계에 있는 플랫폼을 통해 자동으로 수집
		</p><p>（4）서비스 가입, 결제 및 사용 중 고객응대 시 이용자의 자발적 제공 또는 필요에 의해 요청 후 수집
		</p><p>제 4 조 (개인 정보의 수집보유 및 이용기간)
		</p><p>（1）원칙적으로 회사는 이용자의 회원가입 후 탈퇴 전까지 이용자 개인정보를 보관하며, 회원탈퇴를 하거나 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.다만 회사는 약관 및 내부 정책에 따라 부정이용의 방지를 위하여 회원탈퇴 후 3개월 동안 개인정보를 보유하며, 이 기간이 경과한 후에는 이용자의 개인정보는 완전히 삭제됩니다. 상품에 관해 문의 시, 이용자로부터 얻은 정보는 문의 답변이 완료 될 때까지 보유 및 이용됩니다. 회사는 문의 답변이 완료된 후3개월 동안 개인정보를 보유하며, 이 기간이 경과한 후에는 이용자의 개인정보는 완전히 삭제됩니다
		</p><p>（2）제1항에도 불구하고, 수집 목적 또는 제공 받은 목적이 달성된 경우에도 상법 등 법령의 규정에 의하여 보존할 필요성이 있는 경우, 회사는 관련법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
		</p><p>- 계약 또는 청약철회 등에 관한 기록
		</p><p>(보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률, 보존 기간 : 5년)
		</p><p>- 대금결제 및 재화 등의 공급에 관한 기록
		</p><p>(보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률, 보존 기간 : 5년)
		</p><p>- 소비자의 불만 또는 분쟁처리에 관한 기록
		</p><p>(보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률, 보존 기간 : 3년)
		</p><p>- 본인확인에 관한 기록
		</p><p>(보존 이유 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 보존 기간 : 6개월)
		</p><p>- 방문에 관한 기록 : 접속 로그, 서비스 이용기록 등
		</p><p>(보존 이유 : 통신비밀보호법, 보존 기간 : 3개월)
		</p><p>（3）위 （2）항에 따라 보유하고 있는 개인정보 등에 대하여 귀하가 열람을 요구하는 경우 회사는 지체 없이 그 내용을 열람, 확인할 수 있도록 조치합니다.
		</p><p>제 5 조 (개인 정보의 제삼자 제공)
		</p><p>당사는 획득한 사용자의 개인 정보에 대해 사용자의 동의 없이 제삼자에게 공개할 수 없습니다. 그런데 다음과 같은 상황이 그러지 않습니다.
		</p><p>（1） 법령에 근거하는 경우
		</p><p>（2） 본인 또는 제삼자의 생명, 신체, 재산 기타의 권리 이익을 해칠 우려가있는 경우
		</p><p>（3） 당사의 업무의 적정한 실시에 현저한 지장을 미칠 우려가있는 경우
		</p><p>제 6 조 (개인정보침해 관한 민원서비스)
		</p><p>이용자의 개인정보와 관련한 문의사항이 있으시면 아래의 개인정보 담당부서로 연락을 주시기 바랍니다. 이용자의 문의사항에 신속하고 성실하게 답변해드리겠습니다. 또한, 회사는 아래와 같이 개인정보관리 책임자와 개인정보관리 담당부서를 두고 있습니다.
		</p><p>* 개인정보 관리 책임자/담당자
		</p><p>- 성 명 : GAO GUICHUAN
		</p><p>- 소 속 : 법인
		</p><p>- 직 위 : 법인
		</p><p>- 이메일 : hongkongdoudou0802@gmail.com
		</p><p>- 주소 : FLAT/RM 705,7/F,FA YUEN COMM BLDG，NO.75,FA YUEN STREET,MONG KOK, KLN，Hong Kong
		</p><p>제7조 (개인정보의 목적 외 이용 및 위탁)
		</p><p>(1)"회사"는 "이용자"의 개인정보를 " 개인정보의 수집 및 이용목적" 에서 고지한 범위 내에서 사용하며 동의 범 위를 초과하여 이용하거나 원칙적으로 제 3자에게 제공하지 않습니다
		</p><p>(2)다만 제휴관계나 위탁처리를 위한 경우 합당한 절차를 통한 회원의 동의를 얻어 개인정보를 제공 또는 이용할 수 있습니다.
		</p><p>-제휴관계: 보다 나은 서비스 제공을 위하여 회사는 회원의 개인정보를 제휴사에 제공할 수 있습니다. 이 경우 사전에 회원에게 제휴사, 제공되는 개인정보의 항목, 개인정보 제공 목적, 제공기간, 개인정보보호방안 등에 대해 개별적으로 동의를 구하는 절차를 거치게 되며, 회원의 동의가 없는 경우에는 제휴사에게 이를 제공하지 않으며, 제휴관계의 변화 또는 종결될 경우에도 고지합니다. 또한 제휴관계가 종결될 경우 제공된 개인정보에 대해서는 회 원님의 동의가 없는 경우 지체 없이 파기하도록 조치하며 개인정보 제공에 동의하더라도 언제든지 그 동의를 철회 할 수 있습니다.
		</p><p>-위탁처리: 원활한 업무처리와 회원에게 보다 나은 서비스 제공을 위하여 회원의 개인정보를 위탁하여 처리할 수 있으며, 이 경우 반드시 사전에 위탁처리 업체명과 목적, 위탁처리 되는 개인정보의 범위, 위탁기간 등에 대해 상세하게 고지합니다
		</p><p>(3)다음은 예외로 합니다.
		</p><p>-"이용자"들이 사전에 동의한 경우
		</p><p>-법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
		</p><p>-"이용자"들이 사전에 동의한 경우
		</p><p>-법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
		</p><p>(4)개인정보 위탁 제공
		</p><p>위탁업체	위탁업무내용	위탁개인정보 항목
		</p><p>JIGUANG	알림 발송	휴대폰 기계정보、위치정보、인터넷정보
		</p><p>제 8 조 (개인 정보 보호 정책의 변경)
		</p><p>본 정책의 내용은 사용자에게 통보없이 변경할 수있는 것으로합니다.
		</p><p>당사가 별도로 정하는 경우를 제외하고는 변경 후의 개인 정보 보호 정책은 당사의 웹 사
		</p><p>이트 또는 당사 서비스에 게재 한 때부터 효력이 발생합니다.
		</p><p>제 9 조 (문의)
		</p><p>본 개인정보취급방침에 관한 문의는 hongkongdoudou0802@gmail.com/으로 연락 주십시오.
		</p>
	</div>
</template>

<script>
</script>

<style>
</style>